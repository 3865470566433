import type { GetServerSideProps, NextPage } from 'next';

import { createSspForVertical, type SspProps } from '@/util/verticalSsp';
import { verticalConfig } from '@/config/verticalConfig';

import { Verticals } from '@/components/Verticals';

const FrontPageFlight: NextPage<SspProps> = (pageProps) => <Verticals config={verticalConfig.flight} {...pageProps} />;

export const getServerSideProps: GetServerSideProps<SspProps> = createSspForVertical(verticalConfig.flight.vertical);

export default FrontPageFlight;
